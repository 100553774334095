import { BatchAction, CustomBatch } from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { Room } from "@/interfaces/room";
import { convertionUnit } from "@/utils/batch-actions.utils";
import { Callback } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchQuantitiesManager.template.vue";
const namespace: string = "ProductModule";
export interface BatchQuantityManagerEvent<T> {
  data: BatchAction<T>;
  valid: boolean;
}
@Component({
  mixins: [Template]
})
export class BatchQuantitiesManagerComponent extends Vue {
  public get dataClone() {
    const cloned = cloneDeep(this.data);
    cloned.batches = reduce(
      cloned.batches,
      (
        acc: {
          [batchID: string]: CustomBatch[];
        },
        batches,
        batchUID
      ) => {
        let filtered = batches.filter(b => !!b.selected);
        filtered = this.getBatchInfo(filtered);
        if (filtered.length) {
          acc[batchUID] = filtered;
        }
        return acc;
      },
      {}
    );
    if (this.batchActionName === "ADJUST") {
      this.setAdjustBatch(cloned);
    }
    return cloned;
  }

  protected get totalUnit() {
    let unit = 0;
    Object.values(this.dataClone!.batches)
      .flat()
      .forEach(batch => {
        unit += batch.unit.includes("u")
          ? +(batch.room_quantity_editable || 0)
          : 0;
      });
    return unit;
  }

  protected get totalGrms() {
    let grs = 0;
    Object.values(this.dataClone!.batches)
      .flat()
      .forEach(batch => {
        grs += batch.unit.includes("g")
          ? +(batch.room_quantity_editable || 0)
          : 0;
      });
    return grs;
  }
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ default: "" }) public batchActionName!: string;
  @Action("setAdjustBatch", { namespace: "BatchModule" })
  public setAdjustBatch!: Callback;
  @Prop() public completeImmovableBatches!: BatchAction<
    CustomBatch | CustomBatch
  >;
  @Action("loadRooms", { namespace })
  public loadRooms!: Callback;
  /**
   * Original batches data.
   */
  @Prop({ required: true }) public data!: BatchAction<
    CustomBatch | CustomBatch
  >;

  /**
   * List of rooms
   */
  @Prop({ default: () => [] }) public rooms!: Room[];

  /**
   * Used to determine loading state in autocomplete (move).
   */
  @Prop({ default: false }) public loading!: boolean;

  /**
   * Used in case of previous selection of room.
   */
  @Prop({ default: 0 })
  public cachedRoom!: number;

  @Prop({ default: false })
  public readonly!: boolean;

  @Prop({ default: false })
  public isReserved!: boolean;

  @Prop({ required: false })
  public origin!: BatchAction<CustomBatch>;

  public isValid: boolean = true;

  private selectedRoom = 0;

  public mounted() {
    this.selectedRoom = this.cachedRoom;
    this.loadRooms();
  }

  public getBatchInfo(batch: any[]) {
    if (this.batchActionName === "ADJUST") {
      const formattedData: Array<{
        room_id: string | number;
        reserved_quantity: number;
        room_quantity: number;
        room_quantity_editable: number;
      }> = [];
      batch.map(room => {
        const index = formattedData.findIndex(
          roomIndex => roomIndex.room_id === room.room_id
        );
        if (index === -1) {
          formattedData.push(room);
        } else {
          if (!room.reserved_quantity) {
            formattedData[index].room_quantity = room.room_quantity;
            formattedData[index].room_quantity_editable =
              room.room_quantity_editable;
            formattedData[index].reserved_quantity += room.reserved_quantity;
          } else {
            formattedData[index].reserved_quantity = room.reserved_quantity;
          }
        }
      });
      return formattedData;
    }

    return batch;
  }
  /**
   * Updates batch_totals for last batch of batchID group.
   * @param batchID: string
   */
  public onInput(batchID: string, index: number) {
    const length = this.dataClone!.batches[batchID].length;
    let total = 0;

    if (length && length <= 1) {
      this.dataClone!.batches[batchID].forEach(batch => {
        total += +batch.room_quantity_editable!;
      });
      this.dataClone!.batches[batchID][index].room_quantity_editable = total;
      if (!this.isReserved) {
        this.dataClone!.batches[batchID][index].reserved = total;
      }
    }

    if (!this.isReserved && (length && length > 1)) {
      this.dataClone!.batches[batchID][
        index
      ].reserved = this.dataClone!.batches[batchID][
        index
      ].room_quantity_editable;
    }

    this.$nextTick(() => {
      this.updateTotals();
      this.$emit("update", {
        data: this.dataClone,
        valid: this.isValid
      });
    });
  }

  public onMove() {
    this.$emit("move", this.isValid);
  }

  private getTotals(batchInfo: string, editableField = false) {
    let total = 0;
    this.data.batches[batchInfo].forEach(batchInfoIndex => {
      total += editableField
        ? +(batchInfoIndex.room_quantity_editable || 0)
        : batchInfoIndex.room_quantity || 0;
    });

    return total;
  }
  // getting the immovable batch total separately so that no other conditional check is required and code complexity reduces.
  private getImmovableTotals(batchInfo: string, editableField = false) {
    let total = 0;
    this.completeImmovableBatches.batches[batchInfo].forEach(
      (batchInfoIndex: CustomBatch) => {
        total += editableField
          ? +(batchInfoIndex.room_quantity_editable || 0)
          : batchInfoIndex.room_quantity || 0;
      }
    );

    return total;
  }

  private adjustTotal(batchInfo: string, type?: string) {
    let total = 0;
    if (type === "RESERVED") {
      this.data.batches[batchInfo].forEach(batch => {
        total += batch.reserved_quantity!;
      });
    } else {
      this.data.batches[batchInfo].forEach(batch => {
        total += batch.reserved_quantity! + batch.room_quantity;
      });
    }
    return total;
  }

  private getTotalsReserved(batchInfo: string, editableField = false) {
    let total = 0;
    this.data.batches[batchInfo].forEach(
      b => (total += editableField ? +(b.reserved || b.room_quantity) : 0)
    );
    return total;
  }

  private updateTotals() {
    this.dataClone!.total_quantity = 0;
    if (this.$route.name!.includes("combine")) {
      Object.values(this.dataClone!.batches)
        .flat()
        .forEach(batch => {
          let editable = +batch.room_quantity_editable;
          if (
            batch.unit !== PRODUCT_UNIT.GRAMS &&
            batch.unit !== PRODUCT_UNIT.UNIT_READABLE
          ) {
            editable = +convertionUnit(
              batch.unit,
              PRODUCT_UNIT.GRAMS,
              +batch.room_quantity_editable
            );
          }
          this.dataClone!.total_quantity! += editable;
        });
    }
  }

  private onChange() {
    this.$emit("roomChange", this.selectedRoom);
  }
}
