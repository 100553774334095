import { Batch, CustomBatch } from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { convertionUnit } from "@/utils/batch-actions.utils.ts";
import toArray from "lodash/toArray";
import { all, create } from "mathjs";
const math = create(all, {});

export const useEquivalentUsableWeight = (
  data: CustomBatch,
  unitOut: PRODUCT_UNIT = PRODUCT_UNIT.GRAMS
): math.BigNumber => {
  const isCountable = data.unit === PRODUCT_UNIT.UNIT_READABLE;
  const usableWeight = (isCountable && data.usable_weight_value) || 1;
  const currentUnitUsableWeight: PRODUCT_UNIT =
    (isCountable && data.usable_weight_unit) || data.unit;
  const unitBatch = unitOut || data.unit;

  return convertionUnit(currentUnitUsableWeight, unitBatch, usableWeight);
};

export const ungroup = (groupBatch: {
  [batchID: string]: CustomBatch[];
}): CustomBatch[] => {
  return toArray(groupBatch).flat();
};
