import { BatchAction, CustomBatch } from "@/interfaces/batch";
import { ungroup } from "@/utils/convert.utils";
import { sumArray } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./BatchListSelecter.template.vue";

@Component({
  mixins: [Template]
})
export default class BatchListSelecterComponent extends Vue {
  public get isEmpty() {
    const sum = reduce(
      this.batches,
      (acc, item) => acc + sumArray(item, "room_quantity"),
      0
    );

    return !sum && this.$route.name !== "adjust";
  }

  public get listBatch() {
    return cloneDeep(this.batches);
  }

  public get direction(): string {
    return this.expanded ? "down" : "right";
  }
  @Prop({ required: true }) public batches!: {
    [batchID: string]: CustomBatch[];
  };
  @Prop({ default: false }) public details!: boolean;
  @Prop({ default: false }) public fullHeight!: boolean;
  @Prop({ default: "batch_convert.current_quantities" }) public title!: string;
  @Prop() public readOnly!: boolean;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop() public completeImmovableBatches!: BatchAction<CustomBatch> | null;

  public indeterminate = false;
  private expanded = true;
  private selectAll = false;

  public updateListBatch() {
    this.updateSelectAll();
    this.$emit("setBatchSelected", this.listBatch);
  }
  /**
   * Selects the specified product and emits the values.
   * @param batchUid: string
   */
  public selectProduct(batchUid: string, selection: boolean | null) {
    if (selection === null) {
      selection = !this.listBatch[batchUid][0].selected;
    }
    if (!this.readOnly && this.listBatch[batchUid].length) {
      for (const fraction of this.listBatch[batchUid]) {
        fraction.selected = !!selection;
      }
      this.updateListBatch();
    }
  }

  public canEdit(item: CustomBatch[]) {
    return !!item.filter(i => +i.room_quantity).length;
  }

  public showMovableImmovableTemplate() {
    const completeImmovableBatchesLength = this.completeImmovableBatches
      ? Object.keys(this.completeImmovableBatches.batches).length
      : "0";
    if (
      this.bioTrackTraceEnabled &&
      this.$route.name === "batch-move" &&
      (!this.isEmpty || completeImmovableBatchesLength)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Toggles whole list selection action.
   */
  public toggleSelection() {
    this.indeterminate = false;
    forEach(this.listBatch, b => {
      for (const batch of b) {
        batch.selected = this.selectAll && this.canEdit(b);
      }
    });
    this.updateListBatch();
  }

  public getGroupTotal(batches: CustomBatch[]): string {
    const total = sumArray(batches, "room_quantity_editable");
    return `${total} ${batches[0].unit}`;
  }

  public mounted() {
    this.updateSelectAll();
  }

  public onExpand() {
    this.expanded = !this.expanded;
  }

  protected updateSelectAll() {
    const ungrouped = ungroup(this.listBatch);
    const selected = filter(ungrouped, b => !!b.selected);
    this.selectAll = selected.length === ungrouped.length;
    this.indeterminate = selected.length
      ? selected.length !== ungrouped.length
      : false;
  }
}
