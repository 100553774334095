import { BatchAction, CustomBatch } from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { ungroup } from "@/utils/convert.utils";
import { mathJs } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import { Component, Prop, Vue } from "vue-property-decorator";
import BatchListSelecterComponent from "../BatchListSelecter/BatchListSelecter.component";
import { BatchQuantitiesManagerComponent } from "../BatchQuantitiesManager/BatchQuantitiesManager.component";
import Template from "./QuantitiesReservation.template.vue";

@Component({
  mixins: [Template],
  components: {
    "quantities-manager": BatchQuantitiesManagerComponent,
    "list-selector": BatchListSelecterComponent
  }
})
export default class QuantitiesReservationComponent extends Vue {
  @Prop({ required: true }) public data!: BatchAction<CustomBatch> | null;
  @Prop({ default: false }) public disableQuantities!: boolean;
  @Prop({ default: "" }) public batchActionName!: string;
  @Prop() public completeImmovableBatches!: BatchAction<CustomBatch> | null;
  public isValid = false;

  public get currentModel() {
    return cloneDeep(this.data);
  }

  public upadateQuantities(e: {
    data: BatchAction<CustomBatch>;
    valid: boolean;
  }) {
    this.isValid = e.valid;
    e.data.batches = { ...this.currentModel!.batches, ...e.data.batches };
    this.$emit("update", {
      reservation: this.updateHeaderInfoReserve(e.data),
      valid: this.isValid
    });
  }

  public updateSelected(batches: { [batchID: string]: CustomBatch[] }) {
    this.$emit("update", {
      reservation: this.updateHeaderInfoReserve({
        ...this.currentModel!,
        batches
      }),
      valid: !every(batches, (b: CustomBatch[]) => !b[0].selected)
    });
  }

  protected updateHeaderInfoReserve(updateData: BatchAction<CustomBatch>) {
    return ungroup(updateData.batches).reduce(
      (acc, batch) => {
        if (batch.selected) {
          if (batch.unit === PRODUCT_UNIT.UNIT_READABLE) {
            acc.total_weighable = mathJs.add!(
              mathJs.bignumber!(acc.total_countable),
              mathJs.bignumber!(batch.room_quantity_editable || 0)
            ) as number;
          } else {
            acc.total_countable = mathJs.add!(
              mathJs.bignumber!(acc.total_countable),
              mathJs.bignumber!(batch.room_quantity_editable || 0)
            ) as number;
          }
        }
        return acc;
      },
      {
        total_quantity: 0,
        total_weighable: 0,
        total_countable: 0,
        batches: updateData.batches,
        unit: PRODUCT_UNIT.GRAMS,
        isMarijuana: !!updateData.isMarijuana
      }
    );
  }
}
